import { QueryFunctionContext } from 'react-query';
import axios from '../config/axios';
//import queryString from 'query-string';
import HTTPErrorResponseAdapter from '../adapters/HTTPErrorResponseAdapter';
import CustomFieldAdapter from '../adapters/CustomFieldsAdapter';
import { Customer } from 'atons/Customer/atoms';
import { AttributeRelationship } from 'domain/model/AttributeRelationship';
import { HasParent } from '../enums/AttributeTypes';
import { AxiosResponse } from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_URL;

type EmbedCustomFieldGroup = null | {
    id: number;
    name: string;
};

type AttributeValidationOption = {
    id: number;
    name: string;
};

type AttributeGroup = {
    id: number;
    name: string;
};

type AttributeType = {
    id: number;
    name: string;
    description: string;
};

export type PicklistItemResponse = {
    id: number;
    colorCode: string | null;
    isDefault: boolean;
    isDeleted: boolean;
    sortOrder: number | null;
    value: string;
};

export type AttributeDetails = {
    name: string;
    type: string;
};

export type TacticOptionItem = {
    id: string;
    value: string;
};

export type TacticTypeRelationItem = {
    parentOptionId: number;
    childOptionId: number | null;
};

export type Dependency = {
    id?: number;
    parentOptionId: number | null;
    childOptionId: number | null;
};

export type ParentCustomField = {
    id: number;
    customFieldTypeId: number;
    dependencies: Dependency[];
};

export type CustomFieldDependency = {
    userId: string;
    parents: ParentCustomField[];
};

export type TacticTypeDependency = {
    userId: string;
    parents: Pick<ParentCustomField, 'dependencies'>[];
};

export interface CustomFieldDetailResponse {
    abbreviationForMulti: string;
    attributeGroupList?: AttributeGroup;
    attributeType?: AttributeType;
    attributeTypeList: AttributeType[];
    attributeValidationOption: number;
    attributeValidationOptionList: AttributeValidationOption[];
    autoRollup: boolean;
    canHaveParent: boolean;
    hasParentEnum: HasParent;
    customFieldGroup?: EmbedCustomFieldGroup;
    description: string;
    displayInCalendar: boolean;
    entityType: string;
    entityTypeId: number;
    fieldHeight: number;
    fieldWidth: number;
    id: number;
    isCopy: boolean;
    isDeleted: boolean;
    isDisplayForFilter: boolean;
    isDisplayForReview: boolean;
    isEncodeMediaCodeString: boolean;
    isHidden: boolean;
    isMultiChoice: boolean;
    isRequired: boolean;
    isUserAttribute: boolean;
    name: string;
    readOnlyStatus: number | null;
    sortOrder: number;
    tooltip: string;
    userMappingField: string;
    picklistEntries: number;
    childCustomFields: AttributeRelationship[];
    parentCustomFields: AttributeRelationship[];
}

export interface CustomAttributeListItemResponse {
    abbreviationForMulti: string;
    attributeType: AttributeType;
    attributeValidationName: string;
    autoRollup: boolean;
    createdDate: string;
    customFieldGroup?: EmbedCustomFieldGroup;
    description: string;
    displayInCalendar: boolean;
    entityType: string;
    entityTypeId: number;
    id: number;
    isCopy: boolean;
    isDeleted: boolean;
    isDisplayForFilter: boolean;
    isDisplayForReview: boolean;
    isEncodeMediaCodeString: boolean;
    isHidden: boolean;
    isMultiChoice: boolean;
    isRequired: boolean;
    isUserAttribute: boolean;
    name: string;
    picklistEntries: number;
    readOnlyStatus: number | null;
    sortOrder: null | number;
    toolTip: string;
    childCustomFields: AttributeRelationship[];
    parentCustomFields: AttributeRelationship[];
}

export interface EntityTypeBasedOnUserSelectionPayload {
    userId: string;
    entityTypeId: number;
}

export interface EntityType {
    id: number;
    name: string;
    description: string | null;
}

export interface CustomFieldGroup {
    id: number;
    name: string;
    sortOrder: number | null;
    clientId: number;
    entityTypeId: number;
    isApprovalObject: boolean;
}

export interface CustomFieldParent {
    id: number;
    name: string;
    entityTypeId: number;
    attributeTypeId: number;
}

export async function getCustomFieldList({
    queryKey: [, customer, entityTypeByUserIdReturn],
}: QueryFunctionContext<[string, Customer, { entityTypeId: number | null }], any>): Promise<
    CustomAttributeListItemResponse[]
> {
    const response = await axios.get(
        entityTypeByUserIdReturn.entityTypeId !== null
            ? `${baseURL}/acl-legacy/v1/clients/${customer.clientId}/customfields?entityType=${entityTypeByUserIdReturn.entityTypeId}`
            : `${baseURL}/acl-legacy/v1/clients/${customer.clientId}/customfields`
    );
    return response.data;
}

export async function deleteAttributeList(list, customer: Customer) {
    const response = await axios.delete(`${baseURL}/acl-legacy/v1/clients/${customer.clientId}/customfields`, {
        data: list,
    });
    return response.data;
}

export async function toggleDeleteAttribute(id: number, customer: Customer) {
    const response = await axios.patch(
        `${baseURL}/acl-legacy/v1/clients/${customer.clientId}/customfields/${id}/toggledelete`
    );
    return response.data;
}

export async function patchCustomFieldSortOrder<T>(id: number, customer: Customer, payload: T) {
    const response = await axios.patch(
        `${baseURL}/acl-legacy/v1/clients/${customer.clientId}/customfields/${id}/sortorder`,
        payload
    );
    return response.data;
}

export async function getEntityTypeByUserId({
    queryKey: [, userId],
}: QueryFunctionContext<[string, string], any>): Promise<{ entityTypeId: number; userId: string }> {
    const response = await axios.get(`${baseURL}/acl-legacy/v1/EntityTypes/` + userId);
    return response.data;
}

export async function changeEntityTypeUserSelection(payload: EntityTypeBasedOnUserSelectionPayload) {
    const response = await axios.patch(`${baseURL}/acl-legacy/v1/EntityTypes`, payload);
    return response.data;
}

export async function postCustomFieldBulkPickList(clientId, customFieldId, payload) {
    try {
        const response = await axios.post(
            `${baseURL}/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/picklists/createmany`,
            payload
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getCustomFieldDetail({
    queryKey: [, customer, customFieldId],
}: QueryFunctionContext<[string, Customer, number], any>): Promise<CustomFieldDetailResponse> {
    const response = await axios.get<CustomFieldDetailResponse>(
        `${baseURL}/acl-legacy/v1/clients/${customer.clientId}/customfields/${customFieldId}`
    );
    return response.data;
}

export async function getCustomFieldChildrenDependencies({
    queryKey: [, customer, customFieldId],
}: QueryFunctionContext<[string, Customer, number], any>): Promise<AttributeRelationship[]> {
    const response = await axios.get<AttributeRelationship[]>(
        `${baseURL}/acl-legacy/v1/clients/${customer.clientId}/customfields/${customFieldId}/customfielddependencies/children`
    );
    return response.data;
}

export async function getEntityTypes({
    queryKey: [, customer],
}: QueryFunctionContext<[string, Customer], any>): Promise<EntityType[]> {
    const response = await axios.get(`${baseURL}/acl-legacy/v1/EntityTypes?clientId=` + customer.clientId);
    return response.data;
}

export async function getCustomFieldPickList({
    queryKey: [, clientId, customFieldId],
}: QueryFunctionContext<[string, string, number], any>): Promise<PicklistItemResponse[]> {
    const response = await axios.get(
        `${baseURL}/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/picklists`
    );
    return response.data;
}

export async function postCustomFieldPickList(clientId, customFieldId, payload) {
    try {
        const response = await axios.post(
            `/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/picklists`,
            payload
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function deleteCustomFieldPickListItems(clientId, customFieldId, payload) {
    const response = await axios.delete(
        `${baseURL}/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/picklists`,
        {
            data: payload,
        }
    );
    return response.data;
}

export async function postCustomField(attributeSettings, clientId, entityTypeId, userId) {
    try {
        const response = await axios.post(
            `${baseURL}/acl-legacy/v1/clients/${clientId}/CustomFields`,
            CustomFieldAdapter.toPostNewCustomField(attributeSettings, userId, entityTypeId)
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function patchCustomField(attributeSettings, clientId, entityTypeId, userId) {
    try {
        const response = await axios.patch(
            `${baseURL}/acl-legacy/v1/clients/${clientId}/CustomFields/${attributeSettings.id}`,
            CustomFieldAdapter.toPatchNewCustomField(attributeSettings, userId, entityTypeId)
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getCustomFieldGroups({
    queryKey: [, clientId, entityType],
}: QueryFunctionContext<[string, string, number | null], any>): Promise<CustomFieldGroup[]> {
    let url = `${baseURL}/acl-legacy/v1/Clients/${clientId}/CustomFieldGroups`;
    if (entityType !== null) url += `?entityType=${entityType}`;
    const response = await axios.get(url);
    return response.data;
}

export async function getCustomFieldTypes(): Promise<AttributeType[]> {
    try {
        const response = await axios.get(`${baseURL}/acl-legacy/v1/CustomFieldTypes`);
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getCustomFieldValidationOptions() {
    const response = await axios.get(`${baseURL}/acl-legacy/v1/CustomFieldValidationOptions`);
    return response.data;
}

export async function patchCustomFieldPickList(clientId, customFieldId, picklistItemId, payload) {
    try {
        const response = await axios.patch(
            `/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/picklists/${picklistItemId}`,
            payload
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getCustomFieldPossibleParents({
    queryKey: [, clientId, customFieldId],
}: QueryFunctionContext<[string, string, number], any>): Promise<CustomFieldParent[]> {
    try {
        const response = await axios.get(
            `/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/possibleparents`
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getCustomFieldPicklistOptions({
    queryKey: [, clientId, parentCustomFieldId],
}: QueryFunctionContext<[string, string, number], any>): Promise<PicklistItemResponse[]> {
    try {
        const response = await axios.get(
            `/acl-legacy/v1/clients/${clientId}/customfields/${parentCustomFieldId}/picklists?deleted=false`
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getParentDependencies({
    queryKey: [, clientId, customFieldId],
}: QueryFunctionContext<[string, string, number], any>): Promise<ParentCustomField[]> {
    try {
        const response = await axios.get(
            `/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/CustomFieldDependencies/Parents`
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function putParentDependencies(
    clientId: string,
    customFieldId: number,
    obj: CustomFieldDependency
): Promise<void> {
    try {
        const response = await axios.put(
            `/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/CustomFieldDependencies/Parents`,
            obj
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function putTacticTypeDependencies(
    clientId: string,
    customFieldId: number,
    obj: TacticTypeDependency
): Promise<void> {
    try {
        const response = await axios.put(
            `/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/TacticTypeCustomFieldDependencies/Parents`,
            obj
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getTacticTypeCustomFieldDependenciesParents({
    queryKey: [, clientId, customFieldId],
}: QueryFunctionContext<[string, string, number], any>): Promise<TacticTypeRelationItem[]> {
    try {
        const response = await axios.get(
            `/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/TacticTypeCustomFieldDependencies/Parents`
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export type ExportDataQueryParams = {
    entityType?:
        | 'Campaign'
        | 'Program'
        | 'Tactic'
        | 'LineItem'
        | 'Budget'
        | 'MediaCode'
        | 'Plan'
        | 'AccrualRequest'
        | 'AccrualRequestLineItem'
        | 'TacticType';
    includeDeleted?: boolean;
    detailed?: boolean;
};

export async function getDependenciesExportData({
    queryKey: [, clientId, queryParams],
}: QueryFunctionContext<[string, string, ExportDataQueryParams], any>): Promise<AxiosResponse<any>> {
    try {
        const response = await axios.get(`/acl-legacy/v1/clients/${clientId}/customfields/ExportDependenciesToCSV`, {
            responseType: 'blob',
            params: queryParams,
        });
        return response;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getCustomFieldsExportData({
    queryKey: [, clientId, queryParams],
}: QueryFunctionContext<[string, string, ExportDataQueryParams], any>): Promise<AxiosResponse<any>> {
    try {
        const response = await axios.get(`/acl-legacy/v1/clients/${clientId}/customfields/exporttocsv`, {
            responseType: 'blob',
            params: queryParams,
        });
        return response;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getPicklistExportData({
    queryKey: [, clientId, customFieldId],
}: QueryFunctionContext<[string, string, number], any>): Promise<AxiosResponse<any>> {
    try {
        const response = await axios.get(
            `/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/picklists/exporttocsv`,
            {
                responseType: 'blob',
            }
        );
        return response;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getGroupsExportData({
    queryKey: [, clientId, queryParams],
}: QueryFunctionContext<[string, string, Omit<ExportDataQueryParams, 'includeDeleted'>], any>): Promise<
    AxiosResponse<any>
> {
    try {
        const response = await axios.get(`/acl-legacy/v1/clients/${clientId}/Customfieldgroups/exporttocsv`, {
            responseType: 'blob',
            params: queryParams,
        });
        return response;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getEntityTypePossibleOptions({
    queryKey: [, clientId, customFieldId],
}: QueryFunctionContext<[string, string, number], any>): Promise<TacticOptionItem[]> {
    try {
        const response = await axios.get(
            `/acl-legacy/v1/clients/${clientId}/customfields/${customFieldId}/PossibleTacticTypeParentOptions`
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}
