import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import AttributeTypes, { AttributeTypeId } from '../../enums/AttributeTypes';
import { type HasParent } from '../../enums/AttributeTypes';

import { CustomAttributeListItemResponse } from 'services/attributeManagementApis';
import { AttributeRelationship } from 'domain/model/AttributeRelationship';

export const selectedListAtom = atom<number[]>([]);

export const attributeConfigurationModalVisibleAtom = atom(false);

export const addItemToSelectedList = atom(null, (get, set, itemId: number) =>
    set(selectedListAtom, [...get(selectedListAtom), itemId])
);

export const removeItemFromSelectedList = atom(null, (get, set, itemId: number) =>
    set(
        selectedListAtom,
        get(selectedListAtom).filter(id => id !== itemId)
    )
);

export const selectedEntityTypeIdAtom = atom<number | null>(null);

export interface AttributeSettingsData {
    id?: number;
    name: string;
    description: string;
    attributeTypeId: string;
    canHaveParent: boolean;
    hasParentEnum: HasParent;
    customFieldGroupId: string;
    attributeSortOrder: string | null;
    attributeValidationOption: string;
    fieldHeight: string;
    entityType: string;
    entityTypeId: number;
    fieldWidth: string;
    abbreviationForMulti: string;
    tooltip: string;
    isDisplayForFilter: boolean;
    isMultiChoice: boolean;
    isEncodeMediaCodeString: boolean;
    displayInCalendar: boolean;
    autoRollup: boolean;
    isCopy: boolean;
    isRequired: boolean;
    userMappingField: string;
    isUserAttribute: boolean;
    readOnlyStatus: string | null;
    isHidden: boolean;
    isDisplayForReview: boolean;
    isDeleted: boolean;
    picklistEntries: number;
    childCustomFields: AttributeRelationship[];
    parentCustomFields: AttributeRelationship[];
}

export const attributeSettingsDefaultValue: AttributeSettingsData = {
    id: undefined,
    name: '',
    description: '',
    attributeTypeId: '',
    customFieldGroupId: '',
    canHaveParent: false,
    hasParentEnum: 'NoParent',
    attributeSortOrder: '',
    attributeValidationOption: '',
    fieldHeight: '1',
    fieldWidth: '1',
    abbreviationForMulti: '',
    tooltip: '',
    isDisplayForFilter: false,
    isMultiChoice: false,
    isEncodeMediaCodeString: false,
    displayInCalendar: false,
    autoRollup: false,
    isCopy: false,
    isRequired: false,
    userMappingField: '',
    isUserAttribute: false,
    readOnlyStatus: null,
    isHidden: false,
    isDisplayForReview: false,
    isDeleted: false,
    picklistEntries: 0,
    childCustomFields: [],
    parentCustomFields: [],
    entityType: '',
    entityTypeId: 0,
};

export const attributeSettingsAtom = atomWithReset<AttributeSettingsData>(attributeSettingsDefaultValue);
export const attributeSettingsInitialValueAtom = atomWithReset<AttributeSettingsData>(attributeSettingsDefaultValue);

export const nextAttributeIdOfUnsavedChangesModalAtom = atom<number | null>(null);

export const attributesListAtom = atomWithReset<CustomAttributeListItemResponse[]>([]);

export const attributeFormModeAtom = atom(get => {
    const attributeSettings = get(attributeSettingsAtom);
    return !!attributeSettings.id ? 'edit' : 'create';
});

function getDisabledFieldsBasedOnAttributeType(attributeValueId) {
    switch (attributeValueId) {
        case AttributeTypes.TextBox: {
            return ['abbreviationForMulti', 'userMappingField', 'isHidden'];
        }
        case AttributeTypes.DropDownList: {
            return ['attributeValidationOption', 'autoRollup', 'readOnlyStatus'];
        }
        case AttributeTypes.Checkbox: {
            return [
                'attributeValidationOption',
                'autoRollup',
                'abbreviationForMulti',
                'userMappingField',
                'readOnlyStatus',
            ];
        }
        case AttributeTypes.Paragraph: {
            return [
                'attributeValidationOption',
                'isDisplayForFilter',
                'isEncodeMediaCodeString',
                'autoRollup',
                'abbreviationForMulti',
                'userMappingField',
                'readOnlyStatus',
                'isHidden',
            ];
        }
        case AttributeTypes.FileUpload: {
            return [
                'attributeValidationOption',
                'isDisplayForFilter',
                'isEncodeMediaCodeString',
                'displayInCalendar',
                'autoRollup',
                'abbreviationForMulti',
                'userMappingField',
                'readOnlyStatus',
                'isHidden',
            ];
        }
        case AttributeTypes.RichTextEditor: {
            return [
                'attributeValidationOption',
                'isDisplayForFilter',
                'isEncodeMediaCodeString',
                'displayInCalendar',
                'autoRollup',
                'abbreviationForMulti',
                'userMappingField',
                'readOnlyStatus',
                'isHidden',
            ];
        }
        default: {
            return [
                'customFieldGroupId',
                'attributeValidationOption',
                'isDisplayForFilter',
                'isEncodeMediaCodeString',
                'displayInCalendar',
                'autoRollup',
                'abbreviationForMulti',
                'userMappingField',
                'readOnlyStatus',
                'isHidden',
                'attributeSortOrder',
                'fieldHeight',
                'fieldWidth',
                'tooltip',
                'isMultiChoice',
                'isCopy',
                'isRequired',
            ];
        }
    }
}

export const disabledFieldsBasedOnAttributeTypeAtom = atom(get => {
    const attributeTypeId = Number(get(attributeSettingsAtom).attributeTypeId);
    return getDisabledFieldsBasedOnAttributeType(attributeTypeId);
});

export const isChangingAttributeTypeAtom = atom(false);

export const isPickListSectionDisabledAtom = atom(get => {
    const attributeTypeId = Number(get(attributeSettingsAtom).attributeTypeId);
    const attributeFormMode = get(attributeFormModeAtom);
    const isChangingAttributeType = get(isChangingAttributeTypeAtom);
    return attributeFormMode === 'create' || attributeTypeId !== AttributeTypes.DropDownList || isChangingAttributeType;
});

export const isDependencySectionVisibleAtom = atom(get => {
    const attributeFormMode = get(attributeFormModeAtom);
    const isChangingAttributeType = get(isChangingAttributeTypeAtom);
    return attributeFormMode === 'create' || isChangingAttributeType;
});

export const isDependencySectionDisabledAtom = atom(get => {
    const attributeFormMode = get(attributeFormModeAtom);
    const { attributeTypeId, picklistEntries, canHaveParent } = get(attributeSettingsAtom);
    return (
        (canHaveParent && attributeFormMode === 'create') ||
        (AttributeTypes.getById(attributeTypeId as AttributeTypeId) === AttributeTypes.DropDownList &&
            picklistEntries < 1)
    );
});

export const selectedPicklistIdsAtom = atom<number[]>([]);

export const addIdToSelectedPickListAtom = atom(null, (get, set, itemId: number) =>
    set(selectedPicklistIdsAtom, [...get(selectedPicklistIdsAtom), itemId])
);

export const removeIdToSelectedPickListAtom = atom(null, (get, set, itemId: number) =>
    set(
        selectedPicklistIdsAtom,
        get(selectedPicklistIdsAtom).filter(id => id !== itemId)
    )
);

export interface PicklistItem {
    id: number;
    colorCode: string;
    isDefault: string;
    isDeleted: string;
    sortOrder: string;
    value: string;
}

export type PicklistItemFormatted = {
    color: string;
    isDefault: 'No' | 'Yes';
    sortOrder: string;
    value: string;
};

export const selectedPicklistToEdit = atomWithReset<PicklistItem | null>(null);

export const newPicklistRowValueAtom = atomWithReset<PicklistItemFormatted>({
    value: '',
    color: '#FFFFFF',
    isDefault: 'No',
    sortOrder: '',
});

export const selectedAttributeToDeleteOrReactivateAtom = atom<any>(null);

export const parentDependencyMessageAtom = atom<any>(undefined);

export const parentDependencyModalAtom = atom<any>(undefined);

export const includeDeletedValuesOnExportedDataAtom = atom({
    includeDeletedAttributes: false,
    includeDeletedDependencies: false,
});

export const entityTypeListAtom = atomWithReset<any[]>([]);
